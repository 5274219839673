<template>
    <div>
      <Pane :bottom="0" :showRemarks="false">
        <a-tabs
          :tabBarStyle="{
                margin: '0',
            }"
          v-model="active"
        >
          <a-tab-pane key="tab1" tab="合同总览" v-if="$getPermission($route.path + '/contract')"></a-tab-pane>
          <a-tab-pane key="tab2" tab="进度收入总览" v-if="$getPermission($route.path + '/process')"></a-tab-pane>
          <a-tab-pane key="tab3" tab="回款总览" v-if="$getPermission($route.path + '/payment')"></a-tab-pane>
          <a-tab-pane key="tab4" tab="投标总览" v-if="$getPermission($route.path + '/bid')"></a-tab-pane>
          <a-tab-pane key="tab5" tab="部门经营总览" v-if="$getPermission($route.path + '/dept')"></a-tab-pane>
        </a-tabs>
        <TalentTable :year="year" v-if="active !== 'tab5'" style="position: absolute;right: 10px;bottom: 10px;"/>
      </Pane>
      <Tab1 v-if="active === 'tab1'"/>
      <Tab2 v-if="active === 'tab2'"/>
      <Tab3 v-if="active === 'tab3'"/>
      <Tab4 v-if="active === 'tab4'"/>
      <Tab5 v-if="active === 'tab5'"/>
    </div>
  </template>
  
<script>
import TalentTable from "./components/talentTable.vue";
import Tab1 from "./components/tab1.vue";
import Tab2 from "./components/tab2.vue";
import Tab3 from "./components/tab3.vue";
import Tab4 from "./components/tab4.vue";
import Tab5 from "./components/tab5.vue";
export default {
    name: "dataSource",

    components: {
        Tab1,
        Tab2,
        Tab3,
        Tab4,
        Tab5,
        TalentTable
    },

    data() {
        return {
            active: "",
            year: 0,
            yearList: []
        };
    },

    mounted() {
      this.getYearList()
      if (this.$getPermission(this.$route.path + "/contract")) {
        this.active = 'tab1'
      }else if (this.$getPermission(this.$route.path + "/process")) {
        this.active = 'tab2'
      }else if (this.$getPermission(this.$route.path + "/payment")) {
        this.active = 'tab3'
      }else if (this.$getPermission(this.$route.path + "/bid")) {
        this.active = 'tab4'
      }else if (this.$getPermission(this.$route.path + "/dept")) {
        this.active = 'tab5'
      }
    },

    methods: {
      getYearList() {
          const now = new Date().getFullYear()
          for (let index = 2021; index <= now; index++) {
              this.yearList.push(
                  {
                      name: index,
                      value: index
                  }
              )
          }
          this.year = this.yearList[this.yearList.length - 1].value
      },
    }
};
</script>