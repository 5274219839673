<template>
    <div style="padding: 20px;position: relative;">
        <a-button style="position: absolute;top: -42px;right: 250px;" @click="$router.push(`/dataBoard/bid`)">投标管理</a-button>
        <div style="display: flex;">
            <div class="card" style="width: 65%;margin-right: 20px;">
                <div class="title">投标日历</div>
                <Calendar @change="calendarChange" />
            </div>
            <div style="width: 35%;">
                <div class="card">
                    <div class="title">投标情况</div>
                    <div style="position: relative;">
                        <div style="text-align: center;padding-top: 20px;font-size: 16px;">当月招投标情况</div>
                        <div class="tip" v-if="targetInfo.percentA">
                            <div><span class="info">{{ targetInfo.percentA }}</span>%</div>
                        </div>
                        <div id="pie1" style="width: 100%;height: 250px;"></div>
                        <div class="legend">
                            <div>
                                <span class="round" style="background: #e0e0e0;"></span>
                                <span>投标数量：</span>
                                <span>{{ pieA.count }}</span>
                            </div>
                            <div>
                                <span class="round" style="background: #4BA2FF;"></span>
                                <span>中标数量：</span>
                                <span>{{ pieA.winCount }}</span>
                            </div>
                            <div>
                                <span class="round" style="background: #C1BAFE;"></span>
                                <span>未公示：</span>
                                <span>{{ pieA.notPublicCount }}</span>
                            </div>
                        </div>
                    </div>
                    <div style="position: relative;">
                        <div style="text-align: center;padding-top: 20px;border-top: 1px solid #ddd;font-size: 16px;">全年招投标情况</div>
                        <div class="tip" v-if="targetInfo.percentB">
                            <div><span class="info">{{ targetInfo.percentB }}</span>%</div>
                        </div>
                        <div id="pie2" style="width: 100%;height: 250px;"></div>
                        <div class="legend">
                            <div>
                                <span class="round" style="background: #e0e0e0;"></span>
                                <span>投标数量：</span>
                                <span>{{ pieB.count }}</span>
                            </div>
                            <div>
                                <span class="round" style="background: #4BA2FF;"></span>
                                <span>中标数量：</span>
                                <span>{{ pieB.winCount }}</span>
                            </div>
                            <div>
                                <span class="round" style="background: #C1BAFE;"></span>
                                <span>未公示：</span>
                                <span>{{ pieB.notPublicCount }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="display: flex;margin-top: 20px;">
            <div class="card" style="width: 65%;margin-right: 20px;">
                <div class="title">部门招投标情况</div>
                <div style="padding: 10px 20px 10px 20px;">
                    <a-table :bordered="flase" :data-source="deptList" :pagination="false" rowKey="id" :scroll="{ y: 200 }">
                        <a-table-column key="deptName" title="部门" data-index="deptName"></a-table-column>
                        <a-table-column key="count" title="投标数量" data-index="count"></a-table-column>
                        <a-table-column key="winCount" title="中标数量" data-index="winCount"></a-table-column>
                        <a-table-column key="notPublicCount" title="未公示" data-index="notPublicCount"></a-table-column>
                        <a-table-column key="status" title="中标率">
                            <template slot-scope="text">
                                {{ (text.winCount/text.count * 100).toFixed(2) }}%
                            </template>
                        </a-table-column>
                    </a-table>
                </div>
            </div>
            <div class="card" style="width: 35%;padding-top: 20px;">
                <div class="title">中标日志</div>
                <div style="margin: 10px 30px;height: 200px;overflow-y: auto;padding: 0 10px;">
                    <div v-for="item in winLogs" style="font-size: 15px;margin-bottom: 20px;display: flex;justify-content: space-between;align-items: center;">
                        <span style="display: flex;align-items: center;">
                            <span style="color: #FFFFFF;padding: 4px 8px;background: #1890FF;margin-right: 12px;border-radius: 5px;white-space: nowrap;">中标</span>
                            <span style="margin-right: 12px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;max-width: 230px;" :title="item.name">{{ item.name }}</span>
                        </span>
                        <span style="width: 80px;text-align: right;margin-right: 10px;">{{ item.amount?.toFixed(0) }}万</span>
                        <span style="white-space: nowrap;">{{ item.date }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Calendar from "./calendar.vue";
import * as echarts from "echarts/core";
import request from "@/api/request";
import {
    TooltipComponent,
    GridComponent,
    LegendComponent,
} from "echarts/components";
import { BarChart, LineChart, PieChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

function fetchYear(year) {
    return request({
        url: "project-service/dsBid/total/" + year,
    });
}
function fetchMonth(year,month) {
    return request({
        url: "project-service/dsBid/total/" + year + "/" + month,
    });
}
echarts.use([
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BarChart,
    LineChart,
    PieChart,
    CanvasRenderer,
]);
export default {
    data() {
        return {
            deptList: [],
            winLogs: [],
            calendarList: [],
            title: '',
            tableList: [],
            dialogShow: false,
            color: ['#4BA2FF', '#C1BAFE', '#C1BAFE', '#FFA700'],
            targetInfo: {
                percentA: '',
                percentB: '',
                percentC: '',
                percentD: '',
            },
            year: 0,
            month: '',
            yearList: [],
            lastMonth: '',
            pieA:{
                count: '',
                winCount: '',
                notPublicCount: ''
            },
            pieB:{
                count: '',
                winCount: '',
                notPublicCount: ''
            }
        };
    },
    components: {
        Calendar,
    },
    mounted() {
        this.getData()
    },

    methods: {
        calendarChange(data){
            this.year = data.year;
            this.month = data.month;
            this.getData()
        },
        getData() {
            fetchMonth(this.year,this.month).then(res=>{
                this.pieA = res;
                this.renderPie1(res)
            })
            fetchYear(this.year).then(res=>{
                this.pieB = res;
                this.renderPie2(res)
                this.deptList = res.deptList
                this.winLogs = res.winLogs.filter(v=>v.status=='ds_bid_win')
            })
            
        },
        renderPie1(data){
            if (!data) {
                this.pie1 && this.pie1.clear()
                this.targetInfo.percentA = ''
                return
            }
            this.targetInfo.percentA = data.count ? (data.winCount / data.count*100).toFixed(0) : 0
            const list = [
                { name: "投标数量", value: data.count - data.winCount - data.notPublicCount},
                { name: "中标数量", value: data.winCount },
                { name: "未公示", value: data.notPublicCount },
            ]
            let option = {
                color: ["#e0e0e0", this.color[0], this.color[1]],
                series: [
                    {
                        center: ['30%', '40%'],
                        name: "",
                        type: "pie",
                        radius: ['35%', '50%'],
                        label: {
                            show: false,
                        },
                        data: list,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            };
            this.pie1 = echarts.init(document.getElementById("pie1"));
            this.pie1.setOption(option);
        },
        renderPie2(data){
            if (!data) {
                this.pie2 && this.pie2.clear()
                this.targetInfo.percentB = ''
                return
            }
            this.targetInfo.percentB = data.count ? (data.winCount / data.count*100).toFixed(0) : 0
            const list = [
                { name: "投标数量", value: data.count - data.winCount - data.notPublicCount },
                { name: "中标数量", value: data.winCount },
                { name: "未公示", value: data.notPublicCount },
            ]
            let option = {
                color: ["#e0e0e0", this.color[0], this.color[1]],
                series: [
                    {
                        center: ['30%', '40%'],
                        name: "",
                        type: "pie",
                        radius: ['35%', '50%'],
                        label: {
                            show: false,
                        },
                        data: list,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            };
            this.pie2 = echarts.init(document.getElementById("pie2"));
            this.pie2.setOption(option);
        },
    }
};
</script>
<style lang="less" scoped>
.card {
    background: #FFFFFF;
    border-radius: 15px;
    margin-top: 30px;
    position: relative;
    box-shadow: 0px 3px 8px 1px #E4E9F1;
    .tip {
        text-align: center;
        font-size: 12px;
        color: #595959;
        position: absolute;
        top: 41%;
        left: 22%;
        margin: auto;
        width: 100px;
        .info {
            font-weight: bold;
            font-size: 30px;
        }
    }
    .legend{
        position: absolute;
        top: 27%;
        right: 100px;
        font-size: 16px;
        color: #8C8C8C;
        >div{
            margin-bottom: 20px;
            span{
                margin-right: 10px;
            }
            :nth-child(2){
                display: inline-block;
                width: 80px;
            }
            :last-child{
                font-weight: bold;
                font-size: 20px;
                color: rgba(0,0,0,0.65);
            }
        }
    }
}

.item {
    width: 25%;

    margin-right: 10px;
    text-align: center;
    position: relative;

    .header {
        margin-top: 20px;
        font-size: 16px;

        span {
            cursor: pointer;
        }
    }

    .tip {
        font-size: 12px;
        color: #595959;
        position: absolute;
        top: 40%;
        left: 0;
        right: 0;
        margin: auto;

        .info {
            font-weight: bold;
            font-size: 30px;
        }
    }
    .pie {
        width: 100%;
        height: 250px;
    }
}

.title {
    font-size: 14px;
    position: absolute;
    top: -30px;
    left: 30px;
}
.round{
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-right: 5px;
}
</style>