<template>
    <div style="display: flex;border-bottom: 1px solid #ddd">
        <div class="tab-bar">
            <div class="arrow left" @click="scrollLeft" v-if="scroll">
                <a-icon type="left"></a-icon>
            </div>
            <div ref="tabContainer" class="tab-container">
                <div v-if="scroll" class="tab">
                    <div style="background: #4BA2FF;" class="text">{{ now }}</div>
                </div>
                <div v-else v-for="(tab, index) in list" :key="index" class="tab" :style="'width:' + tabWidth">
                    <div v-if="tab.active" style="background: #4BA2FF;" class="text" @click="tabClick(tab.value)">{{ tab.name }}</div>
                    <div v-else class="text" @click="tabClick(tab.value,index)">{{ tab.name }}</div>
                </div>
            </div>
            <div class="arrow right" @click="scrollRight" v-if="scroll">
                <a-icon type="right"></a-icon>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        default: '',
        tabs: [],
        tabWidth: '',
        scroll:  {
            type: Boolean,
            default: true,
        },
    },
    watch:{
        default(newValue) {
            this.now = newValue
            this.list = this.list.map(v=>{
            return {
                    ...v,
                    active:v.value == this.now
                }
            })
        },
        tabs(newValue) {
            this.index = newValue.length - 1;
            this.list = newValue.map(v=>{
            return {
                    ...v,
                    active:v.value == this.now
                }
            })
            console.log(this.list)
        }
    },
    data() {
        return {
            now: '',
            index: 0,
            list:[],
            scrollStep: 100, // 每次滚动的距离，100px
            scrollPosition: 0, // 当前滚动的位置
            tabOffsetWidth: 0, // 元素的可见宽度，包括元素的内容宽度、内边距和边框宽度
            tabScrollWidth: 0,// 元素的内容宽度，包括被隐藏的部分
            tabClientWidth: 0, // 元素的可见宽度，不包括滚动条和边框的宽度
        };
    },
    mounted() {
        this.tabOffsetWidth = this.$refs.tabContainer.offsetWidth;
        this.tabScrollWidth = this.$refs.tabContainer.scrollWidth;
        this.tabClientWidth = this.$refs.tabContainer.clientWidth;
        console.log(this.tabOffsetWidth, this.tabClientWidth, this.tabScrollWidth);
        
    },
    methods: {
        tabClick(value,index){
            this.list = this.list.map(v=>{
                return {
                    ...v,
                    active:false
                }
            })
            this.list[index].active = true
            this.$emit("onSelect", value)
        },
        scrollLeft() {
            if(this.index > 0){
                this.index = this.index - 1
                this.now = this.list[this.index].name
                this.$emit("onSelect", this.list[this.index].value)
            }
        },
        scrollRight() {
            if(this.index < this.list.length - 1){
                this.index = this.index + 1
                this.now = this.list[this.index].name
                this.$emit("onSelect", this.list[this.index].value)
            }
        },
    },
};
</script>
<style>
.tab-bar {
    display: flex;
    align-items: center;
    margin: auto;
}

.arrow {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.left {
    margin-right: 10px;
}

.right {
    margin-left: 10px;
}

.tab-container {
    display: flex;
    overflow-x: hidden;
}

.tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50px;
    text-align: center;
    cursor: pointer;
}

.text {
    padding: 3px 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 20px;
}
</style>