<template>
    <div>
        <a-modal :visible="dialogShow" title="人力资源表" :footer="null" @cancel="dialogShow = false" width="1500px">
            <a-table bordered :dataSource="tableList" :loading="loading" @change="onChange" :pagination="{
            total,
            current,
            pageSize,
            showTotal: (total) => `共 ${total} 条记录`,
        }" rowKey="id">
                <a-table-column title="姓名" align="center">
                    <template slot-scope="text">
                        {{ text?.employee.name }}
                    </template>
                </a-table-column>
                <a-table-column title="从事专业" data-index="professional" align="center"></a-table-column>
                <a-table-column title="部门" data-index="employee.deptName" align="center"></a-table-column>
                <a-table-column title="行政职务" align="center">
                    <template slot-scope="text">
                        <a-select class="selectNoBorder" :disabled="true" show-search
                            :filter-option="$selectFilterOption" size="small" placeholder="请选择" v-model="text.administrativePosition">
                            <a-select-option v-for="item in positionList" :key="item.value" :value="item.value">{{
                            item.name }}</a-select-option>
                        </a-select>
                    </template>
                </a-table-column>
                <a-table-column title="技术职务" align="center">
                    <template slot-scope="text">
                        <a-select class="selectNoBorder" :disabled="true" show-search
                            :filter-option="$selectFilterOption" size="small" placeholder="请选择" v-model="text.technicalPosition">
                            <a-select-option v-for="item in positionList" :key="item.value" :value="item.value">{{
                            item.name }}</a-select-option>
                        </a-select>
                    </template>
                </a-table-column>
                <a-table-column title="职称" align="center">
                    <template slot-scope="text">
                        <DataDictFinder dictType="talentMap.professionalTitle" :dictValue="text?.employee.professionalTitle" />
                    </template>
                </a-table-column>
                <a-table-column title="性别" align="center">
                    <template slot-scope="text">
                        <DataDictFinder dictType="sex" :dictValue="text?.employee.sex" />
                    </template>
                </a-table-column>
                
                <a-table-column title="最高学历" align="center">
                    <template slot-scope="text">
                        <DataDictFinder dictType="degree" :dictValue="text?.employee.degree" />
                    </template>
                </a-table-column>

                <a-table-column title="入职时间" data-index="employee.entryDate" align="center"></a-table-column>
                <a-table-column title="参加工作时间" data-index="employee.workStartTime" align="center"></a-table-column>
                <a-table-column title="工龄" data-index="employee.socialAge" align="center"></a-table-column>
                <a-table-column title="现职称评审时间" data-index="employee.professionalDate" align="center"></a-table-column>
                <a-table-column title="执业注册" align="center">
                    <template slot-scope="text">
                        <DataDictFinder dictType="talentMap.registeredProfessionalQualifications" :dictValue="text?.employee.registeredProfessionalQualifications" />
                    </template>
                </a-table-column>
                <a-table-column-group title="任职资格">
                    <a-table-column title="设计" align="center">
                        <template slot-scope="text">
                            <span v-if="text.design">√</span>
                        </template>
                    </a-table-column>
                    <a-table-column title="校核" align="center">
                        <template slot-scope="text">
                            <span v-if="text.check">√</span>
                        </template>
                    </a-table-column>
                    <a-table-column title="专业负责" align="center">
                        <template slot-scope="text">
                            <span v-if="text.professionalResponsibility">√</span>
                        </template>
                    </a-table-column>
                    <a-table-column-group title="项目负责">
                        <a-table-column title="公司管" align="center">
                            <template slot-scope="text">
                                <span v-if="text.prjComp">√</span>
                            </template>
                        </a-table-column>
                        <a-table-column title="部门管" align="center">
                            <template slot-scope="text">
                                <span v-if="text.prjDept">√</span>
                            </template>
                        </a-table-column>
                    </a-table-column-group>
                    <a-table-column title="审核" align="center">
                        <template slot-scope="text">
                            <span v-if="text.examine">√</span>
                        </template>
                    </a-table-column>
                    <a-table-column-group title="审定">
                        <a-table-column title="公司管" align="center">
                            <template slot-scope="text">
                                <span v-if="text.approvedComp">√</span>
                            </template>
                        </a-table-column>
                        <a-table-column title="部门管" align="center">
                            <template slot-scope="text">
                                <span v-if="text.approvedDept">√</span>
                            </template>
                        </a-table-column>
                    </a-table-column-group>
                    <a-table-column-group title="批准">
                        <a-table-column title="公司管" align="center">
                            <template slot-scope="text">
                                <span v-if="text.approvalComp">√</span>
                            </template>
                        </a-table-column>
                        <a-table-column title="部门管" align="center">
                            <template slot-scope="text">
                                <span v-if="text.approvalDept">√</span>
                            </template>
                        </a-table-column>
                    </a-table-column-group>
                    <a-table-column title="内审员" align="center">
                        <template slot-scope="text">
                            <span v-if="text.internalAuditor">√</span>
                        </template>
                    </a-table-column>
                </a-table-column-group>
                <a-table-column title="备注" data-index="remark" align="center"></a-table-column>
            </a-table>
        </a-modal>
        <span style="margin-right: 20px;">公司人数: {{ total }}</span>
        <a-button type="primary" @click="$router.push(`/dataBoard/talent`)">人力资源表</a-button>
    </div>
</template>
<script>
import request from "@/api/request";
import { mapGetters } from "vuex";
import organization from "@/mixins/organization";
function fetchList(params) {
    return request({
        url: "project-service/dsEmployee/list",
        params: params
    });
}

function fetchNum(params) {
    return request({
        url: "project-service/dsEmployee/count",
        params: params
    });
}
export default {
    mixins: [organization],
    props: {
        year: {
            type: Number,
            default: 0
        }
    },
    computed: {
        ...mapGetters("setting", ["findDataDict"]),
        ...mapGetters("auth", ["isHr"]),
        sex() {
        return this.findDataDict("sex");
        },
        employeeStatusList() {
        return this.findDataDict("employeeStatus");
        },
        lists() {
        return this.findDataDict("talentMap");
        },
        positionList() {
        return (
            this.lists.find((item) => item.value === "talentMap_post").children || []
        );
        },
    },
    data() {
        return {
            now: 0,
            current: 1,
            pageSize: 10,
            total: 0,
            loading: false,
            total: 0,
            dialogShow: false,
            tableList: []
        };
    },
    mounted() {
        fetchNum().then(res => {
            this.total = res
        })

    },
    methods: {
        getList(){
            this.loading = true
            fetchList().then(res => {
                this.tableList = res
                this.dialogShow = true
            }).finally(() => {
                this.loading = false
            })
        },
        onChange(pagination) {
            this.current = pagination.current;
            this.pageSize = pagination.pageSize;
        },
        showDialog() {
            this.getList();
        }
    }
}
</script>
<style>
.selectNoBorder .ant-select-selection{
  border: none;background: none;color: rgba(0, 0, 0, 0.65);
}
.selectNoBorder .ant-select-arrow{
  display: none;
}
.selectNoBorder .ant-select-selection__rendered{
  margin: 0;
}
</style>