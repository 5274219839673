export function formatAmount(res){
    for (let key in res) {
        const target = res[key]
        if(target == null){
            res[key] = 0
        }
        if(target instanceof Object){
            for (let k in target) {
                if(k.includes('Amount') || k.includes('amount')){
                    res[key][k] = res[key][k] ? res[key][k].toFixed(0) : 0    
                }
            }
        }
        if(target instanceof Array){
            target.forEach((el,index)=>{
                for (let k in el) {
                if(k.includes('Amount') || k.includes('amount')){
                    res[key][index][k] = res[key][index][k] ? res[key][index][k].toFixed(0) : 0    
                }
            }
            })
        }
    }
    return res
}