<template>
  <div style="flex: 1; padding: 0 12px">
    <div class="header">
      <div class="title">
        
        <div style="display: flex;justify-content: space-between;">
          <div style="display: flex;align-items: center;width: 80%;">
            <scrollPicker :tabs="yearList" @onSelect="yearChange" :default="year" tabWidth="25%" style="width: 20%;"/>
            <scrollPicker :tabs="monthList" @onSelect="monthChange" :default="month" tabWidth="8%" style="width: 60%;" :scroll="false"/>
          </div>
          <div class="legend">
            <div class="row">
              <div>
                  <span class="round" style="background: #f5222d;"></span>
                  <span>已中标</span>
              </div>
              <div>
                  <span class="round" style="background: #959595;"></span>
                  <span>未中标</span>
              </div>
            </div>
            <div class="row">
              <div>
                  <span class="round" style="background: #FFDE59;"></span>
                  <span>未公示</span>
              </div>
              <div>
                  <span class="round" style="background: #5E92FF;"></span>
                  <span>暂未开标</span>
              </div>
            </div>
        </div>
        </div>
      </div>

      <div class="weeks">
        <div class="week">日</div>
        <div class="week">一</div>
        <div class="week">二</div>
        <div class="week">三</div>
        <div class="week">四</div>
        <div class="week">五</div>
        <div class="week">六</div>
      </div>
    </div>

    <div class="calendar">
      <div class="day" v-for="item in empty" :key="item" style="background: #E6E7E9;"></div>
      <div class="day" v-for="item in filteredDays" :key="String(year) + month + item.date"
        :class="item.date === date ? 'today' : ''" @click="openDetail(item)" :style="(item.winCount||item.notWinCount||item.notPublicCount)?'background:#E6F2FF':''">
        <div class="value">
          {{ item.date }}
        </div>
          <div style="height: 25%;">
            <template v-if="item.winCount" >
              <span v-for="item in new Array(item.winCount)" class="round" style="background: #f5222d;"></span>
            </template>
          </div>
          <div style="height: 25%;">
            <template v-if="item.notWinCount" >
              <span v-for="item in new Array(item.notWinCount)" class="round" style="background: #959595;"></span>
            </template>
          </div>
          <div style="height: 25%;">
            <template v-if="item.notPublicCount" >
              <span v-for="item in new Array(item.notPublicCount)" class="round" style="background: #FFDE59;"></span>
            </template>
          </div>
          <div style="height: 25%;">
            <template v-if="item.notOpenCount" >
              <span v-for="item in new Array(item.notOpenCount)" class="round" style="background: #5E92FF;"></span>
            </template>
          </div>
        <!-- 
        <template v-if="item.list">
          <div
            class="tag"
            v-for="(tag, index) in item.list"
            :key="String(year) + month + item.date + index"
            :class="tag.color"
          >
            <span>{{ tag.title }}</span>
          </div>
        </template> -->
      </div>
    </div>

    <a-modal :visible="visible" title="总院设计合同承接情况" :footer="null" @cancel="visible = false" width="800px">
      <a-table bordered :data-source="detail" :pagination="false" rowKey="id">
        <a-table-column key="name" title="项目名称" data-index="name"></a-table-column>
        <a-table-column key="date" title="开标日期" data-index="date" width="100px"></a-table-column>
        <a-table-column key="status" title="中标情况" width="100px">
          <template slot-scope="text">
            <DataDictFinder
              dictType="ds_bid"
              :dictValue="text.status"
            />
          </template>
        </a-table-column>
        <a-table-column key="amount" title="中标金额" width="100px">
          <template slot-scope="text, record, index">
            <span v-if="text.amount">{{ text.amount }}万元</span>
            <span v-else>-</span>
          </template>
        </a-table-column>
        <a-table-column key="remark" title="未中标原因" width="100px">
          <template slot-scope="text, record, index">
            <span v-if="text.remark">{{ text.remark }}</span>
            <span v-else>-</span>
          </template>
        </a-table-column>
        <a-table-column key="deptName" title="生产院" data-index="deptName" width="120px"></a-table-column>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import scrollPicker from "@/components/scroll-picker";
import {formatAmount} from "@/utils/dataBoard"
import { isLeapYear } from "@/utils/clock";
import request from "@/api/request";
import moment from 'moment'
function fetchList(year, month) {
  return request({
    url: "project-service/dsBid/total/" + year + "/" + month,
  });
}
function fetchDetail(params) {
  return request({
    url: "project-service/dsBid/list",
    params: params
  });
}
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentMonth: 0,
      yearList: [],
      monthList: [],
      year: 0,
      month: 0,
      date: 0,

      empty: 0,

      days: [],

      clockList: [],
      replaceList: [],

      detail: [],
      visible: false,
    };
  },

  computed: {
    filteredDays() {
      return this.days
    },
  },
  components:{
      scrollPicker
  },
  mounted() {
    this.getYearList()
    this.getMonthList()
    let today = new Date();

    this.currentMonth = today.getMonth() + 1;

    this.year = today.getFullYear();
    this.month = this.currentMonth;
    this.date = today.getDate();

    this.init();
  },

  methods: {
    yearChange(value){
      this.year = value
      this.init()
    },
    monthChange(value){
      this.month = value
      this.init()
    },
    getYearList() {
      const now = new Date().getFullYear()
      for (let index = 2021; index <= now; index++) {
          this.yearList.push(
              {
                  name: index,
                  value: index
              }
          )
      }
    },
    getMonthList() {
        for (let index = 1; index <= 12; index++) {
            this.monthList.push(
                {
                    name: index + '月',
                    value: index
                }
            )
        }
    },


    init() {
      this.$emit("change", {
        year: this.year,
        month: this.month,
      });

      this.empty = new Date(this.year, this.month - 1, 1).getDay();

      let days = 0;

      if ([1, 3, 5, 7, 8, 10, 12].indexOf(this.month) > -1) {
        days = 31;
      } else if ([4, 6, 9, 11].indexOf(this.month) > -1) {
        days = 30;
      } else if (this.month === 2) {
        if (isLeapYear(this.year)) {
          days = 29;
        } else {
          days = 28;
        }
      }

      const arr = [];
      for (let i = 1; i <= days; i++) {
        arr.push({
          date: i,
          week: new Date(this.year, this.month - 1, i).getDay(),
        });
      }

      fetchList(this.year, this.month).then(res => {
        arr.forEach((v, index) => {
          const key = moment(this.year + '-' + this.month + '-' + v.date).format("YYYY-MM-DD")
          console.log(key)
          if (res.dateCountMap[key]) {
            arr[index].winCount = res.dateCountMap[key].winCount
            arr[index].notWinCount = res.dateCountMap[key].notWinCount
            arr[index].notPublicCount = res.dateCountMap[key].notPublicCount
            arr[index].notOpenCount = res.dateCountMap[key].notOpenCount
          }
        })
        this.days = arr;
      })
    },

    openDetail(item) {

        fetchDetail({
          date:moment(this.year + '-' + this.month + '-' + item.date).format("YYYY-MM-DD")
        }).then(res=>{
          res = formatAmount(res);
          this.detail = res.list;
          this.visible = true;
        })
        
    },
    cancel() {
      this.detail = [];
      this.visible = false;
    },

  },
};
</script>


<style lang="less" scoped>
.round{
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-right: 5px;
}
.header {
  border-radius: 4px;
  background-color: #fff;

  .title{
    position: relative;
    .legend{
      padding-top: 10px;
      .row{
        display: flex;
        justify-content: center;
        margin-bottom: 5px;
        div{
          margin-right: 10px;
          width: 65px;
        }
      }
      
    }
  }
  .weeks {
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid #e8f0f8;

    display: grid;
    grid-template-columns: repeat(7, 1fr);

    .week {
      text-align: center;
      font-size: 13px;
      color: #667ba9;
    }
  }
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  background-color: #fff;
  border-radius: 4px;
  gap: 8px;
  padding: 12px;

  .day {
    border: 1px solid #e5eef8;
    border-radius: 4px;
    padding: 8px 8px 0 8px;
    min-height: 75px;
    cursor: pointer;
    position: relative;
    .value {
      position: absolute;
      right: 8px;
      top: 8px;
      font-weight: bold;
      font-size: 17px;
      color: #081E5D;
    }

    .tag {
      margin-top: 4px;
      border-radius: 2px;
      color: #02a42f;
      background-color: #e6f8eb;
      text-align: center;
    }

    .red {
      border-radius: 2px;
      background-color: #ffebeb;
      color: #ff3535;
    }
  }

  .today {
    .value {
      color: #0062ff;
    }
  }
}
</style>